// import Repository from "./Repository";
import StreetRepository from "./StreetRepository";
import LeadSubmissionRepository from "./LeadSubmissionRepository";

const repositories = {
    street: StreetRepository,
    leads: LeadSubmissionRepository,
};

export const RepositoryFactory = {
    get: (name) => repositories[name],
};
