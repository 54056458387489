import axios from "axios";
const baseDomain = "https://api.pro6pp.nl/v2/suggest/be";
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: { Authorization: `******` },
});
export default axiosObj;

