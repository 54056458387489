import Repository from "./Repository";

const apiKey = "P6JTU52clKYjZca8";
export default {
    getStreetsByZip(zip) {
       
        return Repository.get(`street?authKey=${apiKey}&postalCode=${zip}`);
    },
    getStreets(payload) {
        return Repository.get(
            `street?authKey=${apiKey}&postalCode=${payload.zip}&street=${payload.street}`
        );
    },
};
