import MainView from "../views/MainView";
import Bedankt from "../views/Bedankt";

let routes = [
    // {
    //     path: "/",
    //     component: MainView,
    //     layout: "main",
    // },
    // {
    //     path: "/badankt",
    //     component: Bedankt,
    //     layout: "main",
    // },
];
export default routes;
