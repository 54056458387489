import { RepositoryFactory } from "../../repository/RepositoryFactory";
var newStraats = RepositoryFactory.get("street");
var leads = RepositoryFactory.get("leads");

export const getStreetsAction =
    (zipOrPayload, isZipAction, onSuccess = () => {}) =>
    async (dispatch) => {
        try {
            let data;
            if (isZipAction) {
                data = await newStraats.getStreetsByZip(zipOrPayload);
            } else {
                data = await newStraats.getStreets(zipOrPayload);
            }

            if (data.data.length > 0) {
                dispatch({
                    type: "NEW_STRAATS_ERROR",
                    payload: { street: false },
                });
                dispatch({
                    type: "NEW_STRAATS",
                    payload: data.data,
                });
                onSuccess();
                console.log(data);
            } else {
                dispatch({
                    type: "NEW_STRAATS",
                    payload: [],
                });
                dispatch({
                    type: "NEW_STRAATS_ERROR",
                    payload: { street: true },
                });
            }
        } catch (e) {
            dispatch({
                type: "NEW_STRAATS_ERROR",
                payload: { street: true },
            });
            dispatch({
                type: "NEW_STRAATS",
                payload: [],
            });
        }
    };

export const postLead =
    (data, onSuccess = () => {}) =>
    async (dispatch) => {
        let { bootCheck, ...payload } = data;
        dispatch({
            type: "LEAD_RESPONSE_LOADER",
            payload: true,
        });
        if (data.bootCheck) {
            dispatch({
                type: "CREATE_RESPONSE",
                payload: {},
            });
            dispatch({
                type: "LEAD_RESPONSE_LOADER",
                payload: false,
            });
            onSuccess();
        } else {
            try {
                let { data } = await leads.postLead(payload);

                if (data) {
                    await dispatch({
                        type: "CREATE_RESPONSE",
                        payload: data,
                    });
                    await dispatch({
                        type: "LEAD_RESPONSE_LOADER",
                        payload: false,
                    });
                    onSuccess();
                } else {
                    dispatch({
                        type: "LEAD_RESPONSE_LOADER",
                        payload: false,
                    });
                    dispatch({
                        type: "CREATE_RESPONSE",
                        payload: {},
                    });
                }
            } catch (e) {
                if (e.response.data.error === "Conflict - duplicate") {
                    await dispatch({
                        type: "CREATE_RESPONSE",
                        payload: {},
                    });
                    dispatch({
                        type: "LEAD_RESPONSE_LOADER",
                        payload: false,
                    });
                    onSuccess();
                } else {
                    // console.error(e.response.data.error);
                    let error = e.response.data.error
                        .match(/'([^']+)'/)[0]
                        .slice(1, -1);
                    dispatch({
                        type: "ERROR_RESPONSE",
                        payload: { [error]: true },
                    });
                    dispatch({
                        type: "LEAD_RESPONSE_LOADER",
                        payload: false,
                    });
                    // alert(e.response.data.error);
                    dispatch({
                        type: "CREATE_RESPONSE",
                        payload: {},
                    });
                }
            }
        }
    };
export const setErrorResponse = () => async (dispatch) => {
    dispatch({
        type: "ERROR_RESPONSE",
        payload: null,
    });
};
