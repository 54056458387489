// Root Reducer

import { combineReducers } from "redux";
import ziekenfondsenReducer from "./ZiekenfondsenReducer";


export let rootReducer = combineReducers({
  ziekenfondsen: ziekenfondsenReducer,
});

export default rootReducer;
