import React from "react";
import { Col, Container, Row } from "reactstrap";
import arrow from "../../assets/img/arrow.png";
import RegistrationForm from "./Form";

function Header() {
    return (
        <Container
            className="hero-section d-flex justify-content-center pt-md-5 pt-2 pb-4"
            fluid
        >
            <Container className="h-75 px-sm-5 px-2">
                <Row className="h-100 ">
                    {/* Content */}
                    <Col md={6} className=" h-100 justify-content-between pt-5">
                        <div className="float-right ml-4">
                            <h1 className="montserrat-font fw--700 fs--36 text-site-heading-dark">
                                Ziekenfondsen vergelijken in België
                            </h1>
                            {/* <span className="MontserratFont fw--700 fs--28">
                                Ziekenfondsen vergelijken in België
                            </span> */}
                            <p className="mt-4 roboto-font text-site-gray fs--16">
                                Weet u niet welke ziekenfonds het best bij u
                                past? Met onze vergelijkingsdienst kunt u gratis
                                en vrijblijvend offertes van de beste
                                ziekenfondsen van het land vergelijken. Ontvang
                                aanbiedingen op maat van uw budget en situatie.
                            </p>
                        </div>
                        <div className="float-right w-50 mt-5 pt-5  d-md-block d-none">
                            <h6 className="nanum-font mb-0 fs--24 fw--500">
                                Elke maand vergelijken meer dan 25.000 Belgen
                                prijzen dankzij Mijn-Offertes!
                            </h6>
                            <img src={arrow} className="float-right"></img>
                        </div>
                        <div className="text-center w-100  d-md-none d-block">
                            <button
                                className="rounded-pill w-75 py-4 px-5 mb-4 mt-2 btn-style"
                                onClick={() => {
                                    document
                                        .getElementById("form")
                                        .scrollIntoView({ behavior: "smooth" });
                                }}
                            >
                                <h5 className="mb-0">Vergelijk vrijblijvend</h5>
                            </button>
                        </div>
                    </Col>
                    {/*    Form */}
                    <Col id={"form"}>
                        <RegistrationForm />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Header;
