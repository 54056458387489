const initialState = {
    straats: [],
    create_response: null,
    error_response: null,
    straat_error: null,
    loader: false,
};

export default function ziekenfondsenReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case "NEW_STRAATS":
            return {
                ...state,
                straats: payload,
            };
        case "NEW_STRAATS_ERROR":
            return {
                ...state,
                straat_error: payload,
            };
        case "ERROR_RESPONSE":
            return {
                ...state,
                error_response: payload,
            };
        case "LEAD_RESPONSE_LOADER":
            return {
                ...state,
                loader: payload,
            };
        case "CREATE_RESPONSE":
            return {
                ...state,
                create_response: payload,
            };

        default:
            return { ...state };
    }
}
